import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
import messagejk from "./messagejk";
import baseInfo from "./baseInfo";
import sysConfig from "./sysConfig";
import kanlist from "./kanlist";
import operations from "./Operations";
import benchs from "./benchs";
import control from "./control";
export default new Router({
  routes: [
    // {
    //     path: '/',
    //     redirect: '/kfjk'
    // },
    ...kanlist,
    ...benchs,
    {
      path: "/",
      redirect: "/kfjk",
      component: (resolve) =>
        require(["../components/common/Home.vue"], resolve),
      meta: { title: "自述文件" },
      children: [
        ...messagejk,
        ...baseInfo,
        ...sysConfig,
        ...operations,
        {
          path: "/403",
          component: (resolve) =>
            require(["../components/page/403.vue"], resolve),
          meta: { title: "403" },
        },
      ],
    },
    {
      path: "/login",
      component: (resolve) =>
        require(["../components/page/Login.vue"], resolve),
    },
    // 酒店工作台-监控及控制页面
    ...control,
    {
      path: "/404",
      component: (resolve) => require(["../components/page/404.vue"], resolve),
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});
