const list = [
  {
    path: "/informationManagement",
    component: (resolve) =>
      require(["../components/page/mdInfo/informationManagement.vue"], resolve),
    meta: {
      title: "酒店管理信息",
      authority: "informationManagement:view",
    },
  },
  {
    path: "/kan-list/flying-room",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/FlyingRoom.vue"], resolve),
    meta: {
      title: "飞房看板",
      authority: "FlyingRoom:view",
    },
  },
  {
    path: "/mdMapwr",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapwr.vue"], resolve),
    meta: {
      title: "万润看板",
      authority: "mdMapwr:view",
    },
  },
  {
    path: "/mdMapWrNo2",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapWrNo2.vue"], resolve),
    meta: {
      title: "万润看板",
      authority: "mdMapWrNo2:view",
    },
  },

  {
    path: "/mdMapZhaos",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapZhaos.vue"], resolve),
    meta: {
      title: "招商",
      authority: "mdMapZhaos:view",
    },
  },

  {
    path: "/mdMapBoss",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapBoss.vue"], resolve),
    meta: {
      title: "总裁看板",
      authority: "mdMapBoss:view",
    },
  },

  {
    path: "/mdMapInvestor",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapInvestor.vue"], resolve),
    meta: {
      title: "投资人看板",
      authority: "mdMapInvestor:view",
    },
  },

  {
    path: "/mdMapAudit",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapAudit.vue"], resolve),
    meta: {
      title: "稽查看板",
      authority: "mdMapAudit:view",
    },
  },
  {
    path: "/mdMapFrontDesk",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapFrontDesk.vue"], resolve),
    meta: {
      title: "前台看板",
      authority: "mdMapFrontDesk:view",
    },
  },
  {
    path: "/mdMapWork",
    component: (resolve) =>
      require(["../components/page/mdInfo/hpPage/mdMapWork.vue"], resolve),
    meta: {
      title: "工程看板",
      authority: "mdMapWork:view",
    },
  },
];

export default list;
