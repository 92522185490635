 const messagejk=[
          {
            // 故障监控页面
            path: '/gzjk',
            component: resolve => require(['../components/page/messagejk/gzjk.vue'], resolve),
            meta: { title: '故障监控', authority:"deviceFaultAlarm:view" }
        },
        {
            // 异常记录
            path: '/exceptionLog',
            component: resolve => require(['../components/page/messagejk/exceptionLog/index.vue'], resolve),
            meta: { title: '异常记录', authority:"exceptionLog:view" }
        },
        // {
        //     // 故障信息页面
        //     path: '/gzxx',
        //     component: resolve => require(['../components/page/messagejk/gzxx.vue'], resolve),
        //     meta: { title: '故障信息', authority:"deviceFault:view"}
        // },
        {
            // 客房监控页面
            path: '/kfjk',
            component: resolve => require(['../components/page/messagejk/kfjk.vue'], resolve),
            meta: { title: '客房监控', authority:"roomStatus:view"}
        },
        {
            // 客房事件页面
            path: '/kfsj',
            component: resolve => require(['../components/page/messagejk/kfsj.vue'], resolve),
            meta: { title: '客房事件', authority:"roomStatused:view"}
        },
    
        {
            // 设备模式页面
            path: '/sbms',
            component: resolve => require(['../components/page/messagejk/sbms.vue'], resolve),
            meta: { title: '季节模式', authority:"mqtt:seasonal"}
        },
        {
            // 飞房页面
            path: '/feifang',
            component: resolve => require(['../components/page/messagejk/feifang.vue'], resolve),
            meta: { title: '飞房报表', authority:"feifng:view"}
        },
        {
            // 飞房页面
            path: '/feifangStatistics',
            component: resolve => require(['../components/page/messagejk/feifangRoom.vue'], resolve),
            meta: { title: '飞房统计', authority:"fRoom:view"}
        },

        {
            path: '/mdKanban',
            component: resolve => require(['../components/page/mdInfo/mdKanban.vue'], resolve),
            meta: { title: '门店看板' ,authority:"mdKanban:view"}
        },
]
export default messagejk