// import 'babel-polyfill'
// import '@babel/polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/index'
import Axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/css/icon.css';
import './components/common/directives';
import "babel-polyfill";
import "./assets/font/iconfont.css";
import './assets/css/color-dark.css'; // 主题颜色修改
import "./assets/css/main.scss";
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import dataV from '@jiaminghi/data-view'
import VueParticles from'vue-particles'
Vue.use(VueParticles)
Vue.use(dataV)


// 设置上线时不产生提示信息
Vue.config.productionTip = false
// 使用element-ui插件
Vue.use(ElementUI, {
    size: 'small'
});
Vue.prototype.$axios = Axios;

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    let user = store.state.token
    if (!user && to.path != '/login') {
        next('/login')
    } else {
        if (to.meta.authority) {
            if (store.state.roleMenu.includes(to.meta.authority)) {
                next()
            } else {
                next('/403');
            }
        } else {
            next()
        }
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')