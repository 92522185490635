const list = [
 
  {
      path: '/shopManager',
      component: resolve => require(['../components/page/workbench/shopManager/index.vue'], resolve),
      meta: {
          title: '店长工作台',
          authority:'shopManager:view'
      }
  },
//   {
//     path: '/hotelManager',
//     component: resolve => require(['../components/page/workbench/hotelManager/index.vue'], resolve),
//     meta: {
//         title: '店总工作台',
//         // authority:'hotelManager:view'
//     }
// },
 

  
  
]

export default list