const baseInfo = [
  {
    // 酒店管理的品牌页面
    path: "/pp",
    component: (resolve) =>
      require(["../components/page/baseInfo/pp.vue"], resolve),
    meta: { title: "品牌", pp: true, authority: "brand:view" },
  },
  {
    // 酒店管理的门店页面
    path: "/md",
    component: (resolve) =>
      require(["../components/page/baseInfo/hotelInit/md.vue"], resolve),
    meta: { title: "门店", md: true, authority: "hotel:view" },
  },
  {
    // 酒店管理的建筑页面
    path: "/jz",
    component: (resolve) =>
      require(["../components/page/baseInfo/jz.vue"], resolve),
    meta: { title: "建筑", jz: true, authority: "building:view" },
  },
  {
    // 酒店管理的楼层页面
    path: "/lc",
    component: (resolve) =>
      require(["../components/page/baseInfo/lc.vue"], resolve),
    meta: { title: "楼层", lc: true, authority: "floor:view" },
  },
  {
    // 酒店管理的客房页面
    path: "/kf",
    component: (resolve) =>
      require(["../components/page/baseInfo/kf.vue"], resolve),
    meta: { title: "客房", kf: true, authority: "room:view" },
  },
  {
    // 酒店管理的房间类型页面
    path: "/fjlx",
    component: (resolve) =>
      require(["../components/page/baseInfo/fjlx.vue"], resolve),
    meta: { title: "房间类型", fjlx: true, authority: "roomType:view" },
  },
  {
    // 设备管理页面
    path: "/sbgl",
    component: (resolve) =>
      require(["../components/page/baseInfo/sbgl/sbgl.vue"], resolve),
    meta: { title: "设备管理", sbgl: true, authority: "equipmentInfo:view" },
  },
  {
    // 设备管理页面
    path: "/rcuInfo",
    component: (resolve) =>
      require(["../components/page/baseInfo/rcuInfo.vue"], resolve),
    meta: { title: "设备信息", rcuInfo: true, authority: "rcuInfo:view" },
  },
  {
    // 设备管理页面
    path: "/adsManage",
    component: (resolve) =>
      require(["../components/page/baseInfo/adsManage/index.vue"], resolve),
    meta: { title: "广告管理", adsManage: true },
    // authority:"adsManage:view"
  },
  {
    // 门牌管理
    path: "/doorplateManage",
    component: (resolve) =>
      require([
        "../components/page/baseInfo/doorplateManage/index.vue",
      ], resolve),
    meta: {
      title: "门牌管理",
      doorplateManage: true,
      authority: "door:panel:view",
    },
  },
  {
    // 科飞RCU设备管理页面
    path: "/koofee/appliance",
    component: (resolve) =>
      require([
        "../components/page/baseInfo/koofee/appliance/index.vue",
      ], resolve),
    meta: { title: "科飞设备管理", koofee: true },
    authority: "koofee:app:view",
  },
  {
    // 科飞RCU设备管理页面
    path: "/koofee/scene",
    component: (resolve) =>
      require(["../components/page/baseInfo/koofee/scene/index.vue"], resolve),
    meta: { title: "科飞场景配置", koofee: true },
    authority: "koofee:scene:view",
  },
  {
    // X1RCU设备管理页面
    path: "/xseries",
    component: (resolve) =>
      require(["../components/page/baseInfo/xseries/index.vue"], resolve),
    meta: { title: "X1主机管理", xseries: true },
    authority: "gateway:register:view",
  },
  {
    // PMS管理页面
    path: "/pms",
    component: (resolve) =>
      require(["../components/page/baseInfo/pms/index.vue"], resolve),
    meta: { title: "PMS记录", pms: true },
    authority: "pmsData:view",
  },
  {
    // 入住机人脸核验记录页面
    path: "/person",
    component: (resolve) =>
      require(["../components/page/baseInfo/person/index.vue"], resolve),
    meta: { title: "人脸核验", pms: true },
    authority: "person:view",
  },
  {
    // 插拔卡记录页面
    path: "/electric",
    component: (resolve) =>
      require(["../components/page/baseInfo/electric/index.vue"], resolve),
    meta: { title: "取电记录", electric: true },
    authority: "electric:view",
  },
  // {
  //   // 设备管理页面
  //   path: '/hotelInit',
  //   component: resolve => require(['../components/page/baseInfo/hotelInit/index.vue'], resolve),
  //   meta: { title: '门店初始化', authority:"hotelInit:view"}
  // },
];
export default baseInfo;
