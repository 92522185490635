export default {
  menu: [],
  PlatformName: process.env.VUE_APP_PLATfORM_NAME,
  PlatformLogo: process.env.VUE_APP_PLATfORM_LOGO,
  times: 5,
  collapse: false,
  isPlayVoice: true,
  archivalInformation:
    "<a href='#' style=' color: #409eff;font-size:14px' target='_blank' rel='noopener noreferrer'>粤ICP备20027853号-1</a>",
  roleMenu: sessionStorage.userJurisdiction
    ? JSON.parse(sessionStorage.getItem("userJurisdiction"))
    : [],
  user: sessionStorage.user ? JSON.parse(sessionStorage.getItem("user")) : {},
  token: sessionStorage.token ? sessionStorage.getItem("token") : null,
  color: [
    "#2ec7c9",
    "#b6a2de",
    "#5ab1ef",
    "#ffb980",
    "#d87a80",
    "#8d98b3",
    "#e5cf0d",
    "#97b552",
    "#95706d",
    "#dc69aa",
    "#07a2a4",
    "#9a7fd1",
    "#588dd5",
    "#f5994e",
    "#c05050",
    "#59678c",
    "#c9ab00",
    "#7eb00a",
    "#6f5553",
    "#c14089",
  ],
  hotelData: sessionStorage.hotelData
    ? JSON.parse(sessionStorage.getItem("hotelData"))
    : { brandId: 4, id: 15 },
  color2: [
    "#0263FF",
    "#FF7824",
    "#8E30FF",
    "#3BB16C",
    "#FF4267",
    "#49C5E0",
    "#FFD469",
  ],
  ckList: [
    {
      value: "president",
      icon: require("../../static/images/icon/i_identity6.png"),
      label: "总裁卡",
    },
    {
      value: "manage",
      icon: require("../../static/images/icon/i_identity6.png"),
      label: "管理卡",
    },
    {
      value: "service",
      icon: require("../../static/images/icon/i_identity8.png"),
      label: "服务员卡",
    },
    {
      value: "guest",
      icon: require("../../static/images/icon/i_identity9.png"),
      label: "客人卡",
    },
    {
      value: "noPeople",
      icon: require("../../static/images/icon/i_identity0.png"),
      label: "无人",
    },
  ],
  iconList: [
    // {
    //   icon: require("../../static/images/icon/i_identity6.png"),
    //   name: "总裁卡",
    // },
    {
      icon: require("../../static/images/icon/i_identity6.png"),
      name: "管理卡",
    },
    {
      icon: require("../../static/images/icon/i_identity8.png"),
      name: "服务员卡",
    },
    {
      icon: require("../../static/images/icon/i_identity9.png"),
      name: "客人卡",
    },
    {
      icon: require("../../static/images/icon/i_identity0.png"),
      name: "无人",
    },
    {
      icon: require("../../static/images/icon/i_dnd.png"),
      name: "请勿打扰",
    },

    {
      icon: require("../../static/images/icon/clear_room.png"),
      name: "请即清理",
    },
    // {
    //   icon: require("../../static/images/icon/i_checkout.png"),
    //   name: "退房",
    // },
    {
      icon: require("../../static/images/icon/link.png"),
      name: "主机离线",
    },

    {
      icon: require("../../static/images/icon/i_doorwarning.png"),
      name: "门磁报警",
    },
    {
      icon: require("../../static/images/icon/i_sos.png"),
      name: "SOS",
    },

    {
      icon: require("../../static/images/icon/i_speed0.png"),
      name: "空调(关)",
    },
    {
      icon: require("../../static/images/icon/i_speed1.png"),
      name: "风速(低)",
    },
    {
      icon: require("../../static/images/icon/i_speed2.png"),
      name: "风速(中)",
    },
    {
      icon: require("../../static/images/icon/i_speed3.png"),
      name: "风速(高)",
    },
    {
      icon: require("../../static/images/icon/i_speed_auto.png"),
      name: "风速(自动)",
    },
    // {
    //   icon: require("../../static/images/icon/face_inline.png"),
    //   name: "门牌在线",
    // },
    // {
    //   icon: require("../../static/images/icon/face_offline.png"),
    //   name: "门牌离线",
    // }
  ],
  hostType: ["SMTE", "X1", "L1", "R1000/R2000", "KOOFEE", "R3000"],
  operationState: [
    {
      lable: "在营",
      value: "0",
      type: "success",
      bar: "",
    },
    {
      lable: "解约",
      value: "1",
      type: "warning",
      bar: "",
    },
    {
      lable: "签约",
      value: "2",
      type: "",
      bar: "",
    },
    {
      lable: "筹建",
      value: "3",
      type: "",
      bar: "",
    },
    {
      lable: "停业",
      value: "4",
      type: "danger",
      bar: "",
    },
    {
      lable: "废止",
      value: "5",
      type: "danger",
      bar: "",
    },
  ],
  // 房间类型
  roomTypeList: [
    {
      lable: "单人标间",
      value: "S1",
    },
    {
      lable: "双人标间",
      value: "S2",
    },
    {
      lable: "无障碍客房",
      value: "B1",
    },
    {
      lable: "C1套房",
      value: "C1",
    },
    {
      lable: "C2套房",
      value: "C2",
    },
  ],
  // 卡类型
  cardTypes: [
    {
      label: "服务员",
      value: 0,
    },
    {
      label: "维修工",
      value: 1,
    },
    {
      label: "客房主管",
      value: 2,
    },
    {
      label: "店长",
      value: 3,
    },
  ],
  // 卡状态
  cardStatus: [
    {
      label: "闲置",
      value: 0,
    },
    {
      label: "已使用",
      value: 1,
    },
    {
      label: "已禁用",
      value: 2,
    },
  ],
  orderTypeList: [
    {
      label: "系统工单",
      value: 0,
    },
    {
      label: "维修工单",
      value: 1,
    },
    {
      label: "送物工单",
      value: 2,
    },
    {
      label: "做房工单",
      value: 3,
    },
    {
      value: "6",
      label: "洗衣",
    },
    {
      value: "5",
      label: "申请发票",
    },
    {
      value: "4",
      label: "预约退房",
    },
  ],
  orderStatusList: [
    {
      label: "待认领",
      value: 0,
    },
    {
      label: "已认领",
      value: 1,
    },
    {
      label: "转派",
      value: 2,
    },
    {
      label: "返工",
      value: 3,
    },
    {
      label: "待验收",
      value: 4,
    },
    {
      label: "已验收",
      value: 5,
    },
  ],

  r3000: {
    switch: "0x8B", //开关面板（WR3000）
    scene: "0x8C", //场景面板（WR3000）
    clear: "0xA0",
    // "0x90",//清理勿扰（WR3000） 0xA0
    dimming: "0x8D", //调光面板（WR3000）
    dimming1: "0x8D_01", //调光单亮度 （WR3000）
    dimming2: "0x8D_02", //调光亮度色温（WR3000）
    dimming4: "0x8D_04", //调光RGB调光（WR3000）
    dimming5: "0x8D_05", //调光RGB亮度色温调光（WR3000）
    temperatureControl: "0x8E", //温控面板（WR3000）
    curtain: "0x8F", //	窗帘电机（WR3000）
  },
  clearSceneHas: ["0xA8", "0xA9"],
  bySelfCode: "0xC", //自定义场景的code
  icons: {
    edit: {
      icon: "el-icon-edit",
      type: "primary",
      name: "修改",
    },
    delete: {
      icon: "el-icon-delete",
      type: "danger",
      name: "删除",
    },
    view: {
      icon: "el-icon-view",
      type: "primary",
      name: "查看",
    },
    stick: {
      icon: "el-icon-stick",
      type: "primary",
      name: "同步",
    },
    // stick:{icon:"el-icon-stick",type:'primary',name:"同步"},
    // stick:{icon:"el-icon-stick",type:'primary',name:"同步"},
    // stick:{icon:"el-icon-stick",type:'primary',name:"同步"},
    // stick:{icon:"el-icon-stick",type:'primary',name:"同步"},
    // stick:{icon:"el-icon-stick",type:'primary',name:"同步"},
  },
};
