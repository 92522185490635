const sysConfig=[
    // sysConfig
          {
            // 角色管理页面
            path: '/jsgl',
            component: resolve => require(['../components/page/sysConfig/jsgl.vue'], resolve),
            meta: { title: '角色管理', jsgl: true ,authority:"role:view"}
        },
        {
            // 用户管理页面
            path: '/yhgl',
            component: resolve => require(['../components/page/sysConfig/yhgl.vue'], resolve),
            meta: { title: '用户管理', yhgl: true ,authority:"user:view"}
        },

        {
            // 应用配置页面
            path: '/yypz',
            component: resolve => require(['../components/page/sysConfig/yypz.vue'], resolve),
            meta: { title: '权限管理', yypz: true ,authority:"menu:view"}
        },
        {
            // 操作日志页面
            path: '/czrz',
            component: resolve => require(['../components/page/sysConfig/czrz.vue'], resolve),
            meta: { title: '操作日志', czrz: true ,authority:"log:view"}
        },
        {
            // 字典管理页面
            path: '/zdgl',
            component: resolve => require(['../components/page/sysConfig/zdgl.vue'], resolve),
            meta: { title: '字典管理', zdgl: true ,authority:"dict:view"}
        },
        {
            // 授权管理页面
            path: '/sqgl',
            component: resolve => require(['../components/page/sysConfig/sqgl.vue'], resolve),
            meta: { title: '授权管理', sqgl: true ,authority:"oauth:view"}
        },
        
        {
            // 授权推送
            path: '/pushSet',
            component: resolve => require(['../components/page/sysConfig/pushSet.vue'], resolve),
            meta: { title: '授权推送', feifangRoom: true ,authority:"ReportPush:feifang"}
        },
        {
            // 生成授权码
            path: '/codeInsert',
            component: resolve => require(['../components/page/sysConfig/codeInsert.vue'], resolve),
            meta: { title: '生成授权码',authority:"codeInsert:view"}
        },
        
]

export default  sysConfig