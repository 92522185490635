export default [{
    path: '/makeRoom',
    component: resolve => require(['../components/page/Operations/makeRoom/index.vue'], resolve),
    meta: {
      title: '做房报表',
      authority: "makeRoom:view"
    }
  },
  {
    path: '/checkRoom',
    component: resolve => require(['../components/page/Operations/checkRoom/index.vue'], resolve),
    meta: {
      title: '查房报表',
      authority: "checkRoom:view"
    }
  },
  {
    path: '/workerTrack',
    component: resolve => require(['../components/page/Operations/workerTrack/index.vue'], resolve),
    meta: {
      title: '人员行踪分析',
      authority: "workerTrack:view"
    }
  },
  {
    path: '/roomManage',
    component: resolve => require(['../components/page/Operations/roomManage/index.vue'], resolve),
    meta: {
      title: '客房管理明细',
      authority: "roomManage:view"
    }
  },
  {
    path: '/orderManage',
    component: resolve => require(['../components/page/Operations/orderManage/index.vue'], resolve),
    meta: {
      title: '工单管理',
      authority: "orderManage:view"
    }
  },
  {
    path: '/staffManage',
    component: resolve => require(['../components/page/Operations/staffManage/index.vue'], resolve),
    meta: {
      title: '员工管理',
      authority: "staffManage:view"
    }
  },
  {
    path: '/staffAuthorityManage',
    component: resolve => require(['../components/page/Operations/staffAuthorityManage/index.vue'], resolve),
    meta: {
      title: '员工权限管理',
      authority: "staffAuthorityManage:view"
    }
  },
  {
    path: '/cardManage',
    component: resolve => require(['../components/page/Operations/cardManage/index.vue'], resolve),
    meta: {
      title: '取电卡管理',
      authority: "cardManage:view"
    }
  },
  {
    path: '/messagePush',
    component: resolve => require(['../components/page/Operations/messagePush/index.vue'], resolve),
    meta: {
      title: '消息推送记录',
      authority: "messagePush:view"
    }
  },
  {
    path: '/expendManage',
    component: resolve => require(['../components/page/Operations/expendManage/index.vue'], resolve),
    meta: {
      title: '能耗管理',
      authority: "expendManage:view"
    }
  },
]