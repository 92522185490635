import * as types from './mutations-type'

export default {
  [types.GET_MENU_LIST](state, data){
    state.menu = data
  },
  [types.IS_PLAY_VOICE](state, data) {
    state.isPlayVoice = data
  },
  [types.SET_COLLAPSE](state, data) {
    state.collapse = data
  },
  
  [types.ROLE_MENU](state, data) {
    if(data){
      sessionStorage.setItem('userJurisdiction', JSON.stringify(data))
      state.roleMenu = data
    }
  },
  [types.SAVE_USER](state, data) {
    if(data){
      sessionStorage.setItem('user', JSON.stringify(data))
      state.user = data
    }
  },
  [types.SET_TOKEN](state, data) {
    if(data){
      sessionStorage.setItem('token', data)
      state.token = data
    }else{
      sessionStorage.removeItem('token')
      state.token = null
    }
  },
  [types.SAVE_HOTEL](state, data={
    brandId:4,
    id: 15,
     //       brandId:1,
    //       id: 1,
  }) {
    if(data){
      sessionStorage.setItem('hotelData', JSON.stringify(data))
      state.hotelData = data
    }
    
  },
  

  
  
}