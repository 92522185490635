const controlList = [
  //酒店工作台-图表首页
  {
    path: "/control/chart-index",
    name: "ChartIndex",
    component: () =>
      import(
        /* webpackChunkName: "control" */ "../components/page/control/ChartIndex"
      ),
    meta: {
      title: "客控工作台",
      authority: "controlChartIndex:view",
    },
  },
];

export default controlList;
